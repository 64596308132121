import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import { UPDATE_MASTER_ENTITY } from '../../graphql';

const FieldWithEdit = ({ label, value, isEditing, fieldName, editedValues, onChange }) => (
  <Grid item xs={6}>
    <Stack spacing={2}>
      <Typography variant="h6" fontWeight="bold" color="primary">{label}</Typography>
      {isEditing ? (
        <TextField
          variant="filled"
          fullWidth
          value={editedValues[fieldName]}
          onChange={(e) => onChange(fieldName, e.target.value)}
        />
      ) : (
        <Typography variant="h6">{value}</Typography>
      )}
    </Stack>
  </Grid>
);

const UpdateCompanyInformation = ({
  masterEntityId,
  isNaturalPersonRut,
  editedValues,
  setEditedValues,
  isEditing,
  setIsEditing,
  refetch,
  data,
}) => {
  const masterEntity = data?.getMasterEntity;

  const beneficiariesList = masterEntity?.compliance?.beneficiaries || [];
  const beneficiariesString = beneficiariesList
    .map((beneficiary) => beneficiary.masterEntity.name)
    .join(', ');

  const handleInputChange = (field, value) => {
    setEditedValues((prev) => ({ ...prev, [field]: value }));
  };

  const [updateMasterEntity] = useMutation(UPDATE_MASTER_ENTITY);

  const toggleEditMode = async () => {
    if (isEditing) {
      try {
        await updateMasterEntity({
          variables: {
            masterEntityId,
            ...editedValues,
          },
        });
        await refetch();
      } catch (error) {
        console.error('Error al actualizar:', error);
      }
    } else {
      setEditedValues({
        name: masterEntity?.name || '',
        nationality: masterEntity?.company?.legalRepresentative?.[0]?.nationality || '',
        fantasyNameCompliance: masterEntity?.compliance?.fantasyName || undefined,
        phone: masterEntity?.company?.legalRepresentative?.[0]?.phone || '',
        email: masterEntity?.company?.legalRepresentative?.[0]?.email || '',
        profession: masterEntity?.company?.legalRepresentative?.[0]?.profession || '',
        countryName: masterEntity?.country?.name || '',
        contractualPurpose: masterEntity?.company?.legalRepresentative?.[0]?.contractualPurpose || '',
        originFunds: masterEntity?.company?.legalRepresentative?.[0]?.originFunds || '',
      });
    }
    setIsEditing(!isEditing);
  };

  useEffect(() => {
    if (masterEntity) {
      setEditedValues({
        name: masterEntity?.name || '',
        nationality: masterEntity?.company?.legalRepresentative?.nationality || '',
        fantasyNameCompliance: masterEntity?.compliance?.fantasyName || undefined,
        phone: masterEntity?.company?.legalRepresentative?.phone || '',
        email: masterEntity?.company?.legalRepresentative?.email || '',
        profession: masterEntity?.company?.legalRepresentative?.profession || '',
        countryName: masterEntity?.country?.name || '',
        contractualPurpose: masterEntity?.company?.legalRepresentative?.contractualPurpose || '',
        originFunds: masterEntity?.company?.legalRepresentative?.originFunds || '',
      });
    }
  }, [masterEntity]);

  return (
    <Grid
      container
      item
      xs={12}
      width="100%"
      borderRadius={5}
      py={5}
      px={2}
    >
      <FieldWithEdit
        label="Nombre/Razón social"
        value={masterEntity?.name}
        isEditing={isEditing}
        fieldName="name"
        editedValues={editedValues}
        onChange={handleInputChange}
      />
      <Button
        variant="outlined"
        color="primary"
        sx={{
          borderRadius: 3,
          paddingX: 3,
        }}
        onClick={toggleEditMode}
        startIcon={<EditIcon />}
      >
        {isEditing ? 'Guardar' : 'Editar'}
      </Button>
      <Grid item xs={12}>
        <Divider variant="middle" flexItem sx={{ marginY: 2 }} />
      </Grid>
      <FieldWithEdit
        label="Rut"
        value={masterEntity?.displayNationalIdentifier}
        isEditing={false}
        fieldName="rut"
        editedValues={editedValues}
        onChange={handleInputChange}
      />
      <FieldWithEdit
        label="Nacionalidad"
        value={masterEntity?.company?.legalRepresentative?.[0]?.nationality}
        isEditing={isEditing}
        fieldName="nationality"
        editedValues={editedValues}
        onChange={handleInputChange}
      />
      <Grid item xs={12}>
        <Divider variant="middle" flexItem sx={{ marginY: 2 }} />
      </Grid>
      <FieldWithEdit
        label="Número de teléfono"
        value={masterEntity?.company?.legalRepresentative?.[0]?.phone}
        isEditing={isEditing}
        fieldName="phone"
        editedValues={editedValues}
        onChange={handleInputChange}
      />
      <FieldWithEdit
        label="Correo"
        value={masterEntity?.company?.legalRepresentative?.[0]?.email}
        isEditing={isEditing}
        fieldName="email"
        editedValues={editedValues}
        onChange={handleInputChange}
      />
      <Grid item xs={12}>
        <Divider variant="middle" flexItem sx={{ marginY: 2 }} />
      </Grid>
      <FieldWithEdit
        label="Domicilio"
        value={masterEntity?.address}
        isEditing={false}
        fieldName="address"
        editedValues={editedValues}
        onChange={handleInputChange}
      />
      <FieldWithEdit
        label="País"
        value={masterEntity?.country.name}
        isEditing={isEditing}
        fieldName="countryName"
        editedValues={editedValues}
        onChange={handleInputChange}
      />
      <Grid item xs={12}>
        <Divider variant="middle" flexItem sx={{ marginY: 2 }} />
      </Grid>
      <FieldWithEdit
        label="Propósito de la relación legal o contractual, o de la transacción ocasional"
        value={masterEntity?.company?.legalRepresentative?.[0]?.contractualPurpose}
        isEditing={isEditing}
        fieldName="contractualPurpose"
        editedValues={editedValues}
        onChange={handleInputChange}
      />
      <FieldWithEdit
        label="Origen de los fondos"
        value={masterEntity?.company?.legalRepresentative?.[0]?.originFunds}
        isEditing={isEditing}
        fieldName="originFunds"
        editedValues={editedValues}
        onChange={handleInputChange}
      />
      <Grid item xs={12}>
        <Divider variant="middle" flexItem sx={{ marginY: 2 }} />
      </Grid>
      {isNaturalPersonRut ? (
        <Grid item xs={6}>
          <FieldWithEdit
            label="Profesión"
            value={masterEntity?.company?.legalRepresentative?.[0]?.profession}
            isEditing={isEditing}
            fieldName="profession"
            editedValues={editedValues}
            onChange={handleInputChange}
          />
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={12}
          width="100%"
          borderRadius={5}
        >
          <FieldWithEdit
            label="Nombre de fantasía"
            value={masterEntity?.compliance?.fantasyName}
            isEditing={isEditing}
            fieldName="fantasyNameCompliance"
            editedValues={editedValues}
            onChange={handleInputChange}
          />
          <FieldWithEdit
            label="Giro comercial"
            value={masterEntity?.economicActivity}
            isEditing={isEditing}
            fieldName="economicActivity"
            editedValues={editedValues}
            onChange={handleInputChange}
          />
          <Grid item xs={12}>
            <Divider variant="middle" flexItem sx={{ marginY: 2 }} />
          </Grid>
          <FieldWithEdit
            label="Beneficiarios finales"
            value={beneficiariesString}
            isEditing={false}
            fieldName="beneficiariesString"
            editedValues={editedValues}
            onChange={handleInputChange}
          />
        </Grid>
      )}
    </Grid>
  );
};

UpdateCompanyInformation.propTypes = {
  masterEntityId: PropTypes.string.isRequired,
  isNaturalPersonRut: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  editedValues: PropTypes.shape({
    name: PropTypes.string.isRequired,
    nationality: PropTypes.string.isRequired,
    fantasyNameCompliance: PropTypes.string,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    profession: PropTypes.string.isRequired,
    countryName: PropTypes.string.isRequired,
    contractualPurpose: PropTypes.string.isRequired,
    originFunds: PropTypes.string.isRequired,
  }).isRequired,
  setEditedValues: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  data: PropTypes.shape({
    getMasterEntity: PropTypes.shape({
      name: PropTypes.string,
      displayNationalIdentifier: PropTypes.string,
      address: PropTypes.string,
      economicActivity: PropTypes.string,
      country: PropTypes.shape({
        name: PropTypes.string,
      }),
      company: PropTypes.shape({
        legalRepresentative: PropTypes.arrayOf(PropTypes.shape({
          nationality: PropTypes.string,
          phone: PropTypes.string,
          email: PropTypes.string,
          profession: PropTypes.string,
          contractualPurpose: PropTypes.string,
          originFunds: PropTypes.string,
        })),
      }),
      compliance: PropTypes.shape({
        fantasyName: PropTypes.string,
        beneficiaries: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string,
        })),
      }),
    }),
  }).isRequired,
};

FieldWithEdit.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  fieldName: PropTypes.string.isRequired,
  editedValues: PropTypes.shape({
    name: PropTypes.string,
    nationality: PropTypes.string,
    fantasyNameCompliance: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    profession: PropTypes.string,
    countryName: PropTypes.string,
    contractualPurpose: PropTypes.string,
    originFunds: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default UpdateCompanyInformation;
